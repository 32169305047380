import axios, { type AxiosError } from 'axios';

import { type ResponseAPIError } from '../../shared/types/response';

function parseVoucherError(error: Error | unknown | AxiosError): AxiosError {
    let parsedError = error;

    if (axios.isAxiosError(error)) {
        const statusCode = (error as ResponseAPIError).response?.data.statusCode;
        const message = (error as ResponseAPIError).response?.data.message;
        parsedError = { status: statusCode, message };
    }

    return parsedError as AxiosError;
}

export { parseVoucherError };
