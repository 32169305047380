import { useEffect } from 'react';

const useDisableBodyScroll = (shouldDisable: boolean): void => {
    useEffect(() => {
        const originalOverflowStyle = document.body.style.overflow;

        if (shouldDisable) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = originalOverflowStyle;
        }

        return () => {
            document.body.style.overflow = originalOverflowStyle;
        };
    }, [shouldDisable]);
};

export { useDisableBodyScroll };
