import { type ChangeEvent, type FormEvent, type KeyboardEvent } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import {
    ButtonColor,
    ButtonLoader,
    ButtonSize,
    ButtonType,
    ButtonVariant,
    Image,
    LoadingSteps,
    PlusIconSrc,
} from '@jsmdg/yoshi';
import styles from './VoucherForm.module.scss';

const messages = defineMessages({
    voucherFormHeadline: {
        defaultMessage: 'Weiteren Gutschein hinzufügen*',
    },
    searchBarHint: {
        defaultMessage: 'Gib deinen Gutschein-Code ein',
    },
    searchBarButton: {
        defaultMessage: 'Gutschein-Code hinzufügen',
    },
    searchHelperText: {
        defaultMessage:
            '*Du kannst deinen Gutscheinwert durch das Hinzufügen weiterer Gutscheine erhöhen und den gesamten Wert für ein Erlebnis deiner Wahl nutzen.',
    },
});

type VoucherFormProps = {
    readonly inputValue: string;
    readonly step: LoadingSteps;
    readonly error: string | null;
    readonly handleSubmit: (event: FormEvent<HTMLFormElement>) => Promise<void>;
    readonly handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
    readonly handleKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
};

const VoucherForm = ({
    error,
    handleInputChange,
    handleKeyDown,
    handleSubmit,
    inputValue,
    step,
}: VoucherFormProps): JSX.Element => {
    const intl = useIntl();

    return (
        <div className={classNames('mt-3x px-3x py-3x text-wrap', styles.wrapper)}>
            <h3 className={styles.headline}>{intl.formatMessage(messages.voucherFormHeadline)}</h3>
            <form
                method="POST"
                className={classNames(
                    'd-flex position-relative align-items-center mt-1x',
                    styles.form,
                )}
                onSubmit={handleSubmit}
            >
                <input
                    className={classNames('w-100 position-relative', styles.input, {
                        [styles.error]: !!error,
                    })}
                    type="text"
                    placeholder={intl.formatMessage(messages.searchBarHint)}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    size={16}
                    maxLength={19} // allow for 3 whitespaces
                    aria-describedby="voucherError"
                    enterKeyHint="done"
                    name="voucherCode"
                    autoComplete="off"
                />

                <div className="d-flex position-absolute end-0">
                    <ButtonLoader
                        type={ButtonType.Submit}
                        size={ButtonSize.Small}
                        className={classNames('d-flex', styles.searchBarButton)}
                        variant={ButtonVariant.Contained}
                        color={ButtonColor.Brand}
                        a11yText={intl.formatMessage(messages.searchBarButton)}
                        disabled={step >= LoadingSteps.Started && step <= LoadingSteps.LongLoading}
                        isLoading={step >= LoadingSteps.Loading && step <= LoadingSteps.LongLoading}
                        dataTestId="add-voucher-button"
                    >
                        <Image
                            src={PlusIconSrc}
                            alt="add voucher"
                            lazyLoad
                            className={styles.plusIcon}
                        />
                    </ButtonLoader>
                </div>
            </form>

            {error ? (
                <p
                    className={classNames('mt-1x mb-0 w-100', styles.error)}
                    id="voucherError"
                    aria-live="assertive"
                >
                    {error}
                </p>
            ) : null}

            <div className={classNames('mt-3x', styles.searchHelperText)}>
                {intl.formatMessage(messages.searchHelperText)}
            </div>
        </div>
    );
};

export { VoucherForm };
